import { render, staticRenderFns } from "./ClientAssignment.vue?vue&type=template&id=df9bdd50&scoped=true&"
import script from "./ClientAssignment.vue?vue&type=script&lang=js&"
export * from "./ClientAssignment.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df9bdd50",
  null
  
)

export default component.exports