<template>
    <vx-card title="Kundenzuordnung" ref="card">

        <mg-select :options="clients" name="client"
                   @select="selectClient"
                   placeholder="Kunde auswählen"
                   track-by="id" label="company_name" data-vv-as="Kunde" v-validate="'required'">
        </mg-select>

        <vs-list>
            <vs-list-item v-for="client in assignedClients" :title="client.company_name" :subtitle="`Kundennummer: ${client.customer_number}`">
                <vx-tooltip text="Löschen" position="left">
                <vs-button color="primary"
                           type="filled"
                           icon="delete_forever"
                           size="small"
                           @click="removeClient(client.id)"
                ></vs-button>
                </vx-tooltip>
            </vs-list-item>
        </vs-list>
    </vx-card>
</template>

<script>
    import MgSelect from "../../../components/mg-select/MgSelect";
    import ApiService from "../../../api";

    export default {
        name: "ClientAssignment",
        components: {MgSelect},
        props: {
            value: {
                type: Array,
                default: function () {
                    return [];
                },
                required: false
            },
        },
        data() {
            return {
                clients: [],
                assignedClientIds: [],
            }
        },
        mounted() {
            this.assignedClientIds = this.value;
            this.fetchInitialData();
        },
        computed: {
            assignedClients() {
                let clients = [];

                for (let i = 0; i < this.assignedClientIds.length; i++) {
                    let client = this.clients.find(item => item.id === this.assignedClientIds[i]);

                    if(client) {
                        clients.push(client)
                    }
                }

                return clients;
            }
        },
        methods: {
            fetchInitialData() {
                this.$refs.card.refreshcard();

                ApiService.get('clients')
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.$refs.card.removeRefreshAnimation();
                            this.clients = response.data.result;
                        }
                    });
            },
            selectClient(client) {
                if(this.clientIsAlreadyAssigned(client)) {
                    return;
                }

                this.assignedClientIds.push(client.id);

                this.$emit('input', this.assignedClientIds);
            },
            removeClient(id) {
                const index = this.assignedClientIds.indexOf(id);

                if (index > -1) {
                    this.assignedClientIds.splice(index, 1);
                    this.assignedClientIds.push();
                    this.$emit('input', this.assignedClientIds);
                }
            },
            clientIsAlreadyAssigned(client) {
                return this.assignedClientIds.indexOf(client.id) !== -1;
            }
        }

    }
</script>

<style scoped>

</style>